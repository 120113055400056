import React, { useCallback, useEffect, useRef } from 'react';

import api from 'services/api';
import { logger } from 'services/logs/logger';
import { AccessLevel, MetadataStore } from 'stores/metadata';
import {
  FileUploadStore,
  setProgress,
  setAccessLevel,
  setFileInfo,
  setProduct,
  setOrganization,
  setError,
  clearError,
  setStartUpload,
  setFileId,
  deleteFile,
} from 'stores/file-upload';
import DashedArea from 'ui/atoms/DashedArea';
import { Button, ProgressBar, RadioSelection, Select } from 'ui/atoms';
import { ProductAssociation } from 'ui/molecules';

import { ReactComponent as UploadIcon } from './Upload.svg';
import { ReactComponent as WarningIcon } from './warning.svg';
import './file-upload.css';

export const mapFileTypeLabels = (fileTypes) => {
  return fileTypes?.map(({ label }, index) => {
    if (fileTypes.length === 1) {
      return label;
    }

    if (fileTypes.length - 1 === index) {
      return `and ${label} `;
    }

    return `${label}, `;
  });
};

const FileUpload = () => {
  const inputFile = useRef(null);
  const startUpload = FileUploadStore.useState((s) => s.startUpload);
  const isUploading = FileUploadStore.useState((s) => s.isUploading);
  const hasFile = FileUploadStore.useState((s) => s.hasFile);
  const uploadComplete = FileUploadStore.useState((s) => s.uploadComplete);
  const progress = FileUploadStore.useState((s) => s.progress);
  const fileName = FileUploadStore.useState((s) => s.fileName);
  const status = FileUploadStore.useState((s) => s.status);
  const accessLevel = FileUploadStore.useState((s) => s.accessLevel);
  const fileId = FileUploadStore.useState((s) => s.fileId);
  const product = FileUploadStore.useState((s) => s.product);
  const organization = FileUploadStore.useState((s) => s.organization);

  const { hasError, errorMessage } = FileUploadStore.useState((s) => ({
    hasError: s.hasError,
    errorMessage: s.errorMessage,
  }));

  const organizations = MetadataStore.useState((s) => s.organizations);

  const { maxFileSize, fileTypes } = MetadataStore.useState((s) => ({
    maxFileSize: s.fileUploadSizeLimit,
    fileTypes: s.fileUploadFileTypes,
  }));

  const availableFileTypes = fileTypes?.map(({ type }) => type) || [];

  const performUpload = useCallback(() => {
    if (hasFile && inputFile?.current && !uploadComplete && !isUploading) {
      let tempProgress = 5;
      setProgress(tempProgress);
      const progressInterval = setInterval(() => {
        tempProgress = tempProgress + 15;
        if (tempProgress > 95) {
          tempProgress = 95;
        }
        setProgress(tempProgress);
      }, 100);

      api
        .uploadFile((inputFile.current as any).files[0], {
          accessLevel,
          organizationId: organization,
          productId: product,
        })
        .then((res) => {
          progressInterval && clearInterval(progressInterval);
          setProgress(100);
          setFileId(res.id, res.datetimeLastUpdated);
        })
        .catch((error) => {
          progressInterval && clearInterval(progressInterval);
          if (error?.additionalData?.detail) {
            return setError(error.additionalData.detail);
          }
          return setError('There was a problem uploading the file');
        });
    }
  }, [
    accessLevel,
    hasFile,
    organization,
    product,
    uploadComplete,
    isUploading,
  ]);

  useEffect(() => {
    if (!hasFile) {
      if (inputFile.current) {
        (inputFile.current as any).value = '';
      }
    }
  }, [hasFile]);

  useEffect(() => {
    if (hasFile && !startUpload) {
      setStartUpload(true);
    }
  }, [hasFile, startUpload]);

  useEffect(() => {
    if (startUpload) {
      performUpload();
    }
  }, [performUpload, startUpload]);

  if (!fileTypes?.length || !maxFileSize) {
    return null;
  }

  return (
    <div className="file-upload">
      <div
        className="file-upload-area"
        data-testid="file-upload-area"
        onClick={() => {
          if (inputFile?.current && !isUploading) {
            (inputFile.current as HTMLInputElement).click();
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && inputFile?.current) {
            (inputFile.current as HTMLInputElement).click();
          }
        }}
        tabIndex={0}
        role="button"
      >
        {hasError && (
          <DashedArea
            type={'error'}
            buttons={[
              <Button
                key="reset_upload_on_error_button"
                type="outline"
                onClick={() => clearError()}
              >
                Upload another file
              </Button>,
            ]}
          >
            <div className="file-name" data-testid="file-upload-error">
              {errorMessage}
            </div>
          </DashedArea>
        )}

        {!hasError && (
          <DashedArea
            type={uploadComplete ? 'success' : 'default'}
            buttons={(() => {
              if (hasError) {
                return ['Retry'];
              } else if (uploadComplete) {
                return [
                  <Button
                    key="reset_upload_button"
                    type="outline"
                    onClick={() => {
                      if (inputFile.current) {
                        (inputFile.current as any).value = '';
                      }
                      deleteFile(fileId);
                      clearError();
                    }}
                  >
                    Replace this file
                  </Button>,
                ];
              } else {
                return [];
              }
            })()}
          >
            {!hasFile && (
              <div
                className="file-upload-dropzone"
                data-testid="file-upload-dropzone"
              >
                <UploadIcon />
                <div data-testid="file-upload-prompt">
                  <p className="upload-message">
                    Choose a file from your computer
                  </p>
                  <p className="upload-allowed-types">
                    {mapFileTypeLabels(fileTypes)}
                    files accepted
                  </p>
                </div>
              </div>
            )}
            {hasFile && (
              <>
                <div className="file-name">
                  {!uploadComplete ? (
                    <div className="uploading-text">
                      <span>{fileName}</span>
                      <br />
                      Status: <span>{status}</span>
                    </div>
                  ) : (
                    `${fileName} uploaded`
                  )}
                </div>

                {isUploading && !uploadComplete && (
                  <ProgressBar percent={progress} />
                )}
              </>
            )}
          </DashedArea>
        )}
        <input
          type="file"
          name="file"
          accept={availableFileTypes.join(',')}
          ref={inputFile}
          id="fileUpload"
          data-testid="file-upload-input"
          className="inputfile"
          onChange={(e) => {
            if (e.target.files && e.target.files.length > 0) {
              const file = e.target.files[0];

              if (!availableFileTypes.includes(file.type)) {
                logger.error(`Unsupported file type: ${file.type}`);
                return setError('File rejected: Type not allowed');
              }

              if (file.size > maxFileSize) {
                logger.error('File size is too large');
                return setError(
                  `This file is larger than the allowable limit (${Math.ceil(
                    maxFileSize / (1024 * 1024),
                  )} mb).`,
                );
              }
              setFileInfo(e.target.files[0]);
            }
          }}
        />
      </div>
      <div className="info-text">
        <p>
          In the future, Goldie will support document sharing, and may use some
          files marked “Stryker-wide” to train Stryker’s own AI models.
          Carefully consider the sensitivity and confidentiality of the
          information included in this document.
        </p>
      </div>
      <div className="file-type-selector">
        <h2>Privacy level</h2>
        <ul>
          <li>
            <RadioSelection
              label="Private"
              description="Contents within this document are only visible to me."
              name="fileType"
              value={AccessLevel.Personal}
              checked={accessLevel === AccessLevel.Personal}
              onChange={(e) => {
                setAccessLevel(e.target.value);
              }}
            />
          </li>
          <li>
            <RadioSelection
              label="Development review"
              description="Contents within this document may be reviewed by the development team to measure response accuracy and identify new use cases."
              name="fileType"
              value={AccessLevel.Internal}
              checked={accessLevel === AccessLevel.Internal}
              onChange={(e) => {
                setAccessLevel(e.target.value);
              }}
            />
          </li>
          <li>
            <RadioSelection
              label="Model training"
              description="Contents within this document may be used for model training to improve Goldie."
              name="fileType"
              value={AccessLevel.Public}
              checked={accessLevel === AccessLevel.Public}
              onChange={(e) => {
                setAccessLevel(e.target.value);
              }}
            />
          </li>
        </ul>
      </div>
      {accessLevel === AccessLevel.Public && (
        <div className="public-internal-fields">
          <p>What BU or organization is this associated with?&hellip;</p>
          <Select
            placeholder="Associate organization with file"
            options={organizations.map((org) => ({
              value: org.id,
              label: org.name,
            }))}
            value={organization}
            onChange={(value) => {
              setOrganization(value);
            }}
          />
          <ProductAssociation
            value={product}
            onProductSelect={(productName) => {
              setProduct(productName);
            }}
          />
        </div>
      )}
      {accessLevel === AccessLevel.Public && (
        <div className="warning-text">
          <WarningIcon /> Warning! This document may be shared with anyone in
          Stryker.
        </div>
      )}
    </div>
  );
};

export default FileUpload;
